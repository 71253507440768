var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      'shadow-sm',
      _vm.toastBackgroundColor,
      'rounded-[4px]',
      'items-center',
      'gap-3',
      'inline-flex',
      'mb-2',
      'max-w-[25rem]',
      'min-h-[1.875rem]',
      'min-w-[15rem]',
      'px-6',
      'py-3',
      'text-left',
    ]},[_c('div',{staticClass:"text-white dark:text-white text-sm font-medium"},[_vm._v("\n      "+_vm._s(_vm.message)+"\n    ")]),_vm._v(" "),(_vm.action)?_c('div',[(_vm.action.type == 'link')?_c('router-link',{staticClass:"text-woot-500 dark:text-woot-500 cursor-pointer font-medium hover:text-woot-600 dark:hover:text-woot-600 select-none",attrs:{"to":_vm.action.to}},[_vm._v("\n        "+_vm._s(_vm.action.message)+"\n      ")]):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }